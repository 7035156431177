<template>
	<div id="complete" class="container has-text-centered">
		<div id="loading" v-if="loading">
			<h2 class="subtitle mb-5 p-5">Fetching your confirmation</h2>
			<div class="box checkout is-shadowless mt-5 p-5">
				<b-loading :is-full-page="false" v-model="loading" :can-cancel="false"></b-loading>
			</div>
		</div>
		<div id="confirmation" v-if="!loading">
			<h1 class="title is-2">Thanks {{ name.split(' ')[0] }}</h1>
			<h2 class="subtitle is-4">Your {{ mode }} was successful</h2>
			<button class="button is-primary" @click="viewReceipt()">View your receipt</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Complete',
	data: function() {
		return {
			name: '',
			mode: '',
			receiptUrl: '',
			loading: true
		};
	},
	mounted: function() {
		if (!this.$route.query.session_id) {
			this.$router.push({ name: 'home' });
		}

		fetch('/.netlify/functions/complete?session_id=' + this.$route.query.session_id)
			.then(res => res.json())
			.then(res => {
				this.loading = false;

				this.name = res.name;
				this.mode = res.mode;
				this.receiptUrl = res.receipt;

				console.log(res);
			})
			.catch(error => {
				console.error(error);
			});
	},
	methods: {
		viewReceipt(){
			window.open(this.receiptUrl, '_blank');
		}
	}
};
</script>
